@import 'colors';

@font-face {
  font-family: Avenir;
  font-weight: normal;
  src: url(./styles/fonts/AvenirLT-Medium.woff), url(./styles/fonts/AvenirLT-Medium.woff2);
  unicode-range: U+30-39;
}

@font-face {
  font-family: Avenir;
  font-weight: normal;
  font-style: oblique;
  src: url(./styles/fonts/AvenirLT-MediumOblique.woff), url(./styles/fonts/AvenirLT-MediumOblique.woff2);
  unicode-range: U+30-39;
}

@font-face {
  font-family: Avenir;
  font-weight: 300;
  src: url(./styles/fonts/AvenirLT-Light.woff), url(./styles/fonts/AvenirLT-Light.woff2);
  unicode-range: U+30-39;
}

@font-face {
  font-family: Avenir;
  font-weight: 300;
  font-style: oblique;
  src: url(./styles/fonts/AvenirLT-LightOblique.woff), url(./styles/fonts/AvenirLT-LightOblique.woff2);
  unicode-range: U+30-39;
}

@font-face {
  font-family: Avenir;
  font-weight: lighter;
  src: url(./styles/fonts/AvenirLT-Book.woff), url(./styles/fonts/AvenirLT-Book.woff2);
  unicode-range: U+30-39;
}

@font-face {
  font-family: Avenir;
  font-weight: lighter;
  font-style: oblique;
  src: url(./styles/fonts/AvenirLT-BookOblique.woff), url(./styles/fonts/AvenirLT-BookOblique.woff2);
  unicode-range: U+30-39;
}

@font-face {
  font-family: Avenir;
  font-weight: bold;
  src: url(./styles/fonts/AvenirLT-Heavy.woff), url(./styles/fonts/AvenirLT-Heavy.woff2);
  unicode-range: U+30-39;
}

@font-face {
  font-family: Avenir;
  font-weight: bold;
  font-style: oblique;
  src: url(./styles/fonts/AvenirLT-HeavyOblique.woff), url(./styles/fonts/AvenirLT-HeavyOblique.woff2);
  unicode-range: U+30-39;
}

@font-face {
  font-family: Avenir;
  font-weight: bolder;
  src: url(./styles/fonts/AvenirLT-Black.woff), url(./styles/fonts/AvenirLT-Black.woff2);
  unicode-range: U+30-39;
}

@font-face {
  font-family: Avenir;
  font-weight: bolder;
  font-style: oblique;
  src: url(./styles/fonts/AvenirLT-BlackOblique.woff), url(./styles/fonts/AvenirLT-BlackOblique.woff2);
  unicode-range: U+30-39;
}

@font-face {
  font-family: PPObjectSans;
  font-weight: normal;
  src: url(./styles/fonts/PPObjectSans-Regular.ttf);
}

@font-face {
  font-family: PPObjectSans;
  font-weight: 300;
  src: url(./styles/fonts/PPObjectSans-Regular.ttf);
}

@font-face {
  font-family: PPObjectSans;
  font-weight: 400;
  src: url(./styles/fonts/PPObjectSans-Regular.ttf);
}

@font-face {
  font-family: PPObjectSans;
  font-weight: 500;
  src: url(./styles/fonts/PPObjectSans-Medium.ttf);
}

@font-face {
  font-family: PPObjectSans;
  font-weight: bold;
  src: url(./styles/fonts/PPObjectSans-Bold.ttf);
}

@font-face {
  font-family: PPObjectSans;
  font-weight: 900;
  src: url(./styles/fonts/PPObjectSans-Bold.ttf);
}

@font-face {
  font-family: PPObjectSans-Regular;
  src: url(./styles/fonts/PPObjectSans-Regular.ttf);
}

@font-face {
  font-family: PPObjectSans-Medium;
  src: url(./styles/fonts/PPObjectSans-Medium.ttf);
}

@font-face {
  font-family: PPObjectSans-Bold;
  src: url(./styles/fonts/PPObjectSans-Bold.ttf);
}

body,
html {
  color: $dark2;
  font-family: PPObjectSans, sans-serif;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

a {
  color: $dark2;
  font-family: PPObjectSans-Medium, sans-serif;

  &:hover {
    color: $primaryRed;
  }
}

strong {
  font-family: PPObjectSans-Medium, sans-serif;
}

h1,
h2,
h3 {
  font-family: PPObjectSans-Bold, sans-serif;

  strong {
    font-family: PPObjectSans-Bold, sans-serif;
  }
}

h2 {
  color: $navy;
  font-size: 1.75rem;
  line-height: 2.5rem;
}

h3 {
  color: $mineShaft;
  font-size: 1.125rem;
  font-weight: normal;
}

h2.section-header {
  width: 66%;
  text-align: center;
  margin-bottom: 2.5rem;

  @media screen and (max-width: 1039px) {
    width: 100%;
    font-size: 1.75rem;
    margin-bottom: 1.5rem;
  }
}

h3.section-sub-header {
  margin-top: -0.5rem;
  margin-bottom: 2.5rem;
  max-width: 38.125rem;
  text-align: center;

  @media screen and (max-width: 1039px) {
    margin-top: 0;
    margin-bottom: 1.5rem;
  }
}

.content-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 5rem 0;
  align-items: center;
  margin-bottom: 2.5rem;

  @media screen and (max-width: 1039px) {
    padding: 3rem 0 4rem;
  }
}

.panel-card {
  border: none;
  margin-bottom: 1.25rem;
  overflow: hidden;
  padding: 2.5rem 1.5rem;
  background-color: $white;

  @media screen and (min-width: 1024px) {
    padding: 3rem;
  }

  h3 {
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
.inprogress-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  display: flex;
  z-index: 299;
}
.panel-card-header {
  margin: 0;
  background-color: $navy;
  padding: 0.75rem 1.5rem;

  @media screen and (min-width: 1024px) {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  h2 {
    margin: 0;
    padding: 0;
    color: $white;
    font-weight: normal;
    font-size: 1.375rem;
    line-height: 2rem;
  }
}

.panel-card.custom-padding {
  padding: 0;
}

.panel-card.card-error,
.card-error {
  border: solid 1px $crimson;
}

.content {
  margin: 0 auto;
  max-width: 150rem;
  width: calc(100vw - 320px);
  box-sizing: border-box;

  @media (max-width: 1440px) {
    width: calc(100vw - 160px);
  }
  @media (max-width: 1024) {
    width: calc(100vw - 112px);
  }
  @media (max-width: 768px) {
    width: calc(100vw - 80px);
  }
  @media (max-width: 375px) {
    width: calc(100vw - 40px);
  }
}

#Header {
  .content {
    a.logo img {
      width: auto;
    }
    @media (max-width: 1440px) {
      width: calc(100vw - 80px);
    }
  }
}

.hidden {
  visibility: hidden !important;
}

.form-field-row {
  display: flex;
  margin-bottom: 1.2rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  .form-field {
    display: inline-flex;
    flex: 1;
    flex-direction: column;
    margin: 0 0.5rem;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }

    @media screen and (max-width: 1039px) {
      width: 100%;
      margin: 0;
    }
  }
}
.form-field-input {
  border: none;
  border-radius: 0;
  background-color: transparent;
  height: 1.75rem;
  line-height: 1.75;
  padding: 0;
  color: $gray;
  font-weight: normal;
  padding-left: 0.1875rem;
  padding-top: 0.25rem;
  border-bottom: solid 0.0625rem $mineShaft;
  margin-top: 0.75rem;

  &:focus {
    outline: none;
  }
}

select.form-field-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNyIgdmlld0JveD0iMCAwIDE2IDE3Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgZmlsbD0iI0Q5MjcyRCI+CiAgICAgICAgICAgIDxnPgogICAgICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICAgICAgICAgIDxnPgogICAgICAgICAgICAgICAgICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTQgMi40MTRMOS4yOTMgNy43MDcgNCAxMyA1LjQxNCAxNC40MTQgMTIuMTIxIDcuNzA3IDUuNDE0IDF6IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtOTAwIC0zMzMpIHRyYW5zbGF0ZSgwIDk1KSB0cmFuc2xhdGUoMTk5IDIxOCkgdHJhbnNsYXRlKDYxMykgdHJhbnNsYXRlKDAgMTYuNTkzKSB0cmFuc2xhdGUoODggNCkgbWF0cml4KDAgMSAxIDAgLjM1MyAtLjM1MykiLz4KICAgICAgICAgICAgICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+Cg==');
  background-repeat: no-repeat;
  background-position: center right;
}
select.form-field-input:disabled {
  background: none;
}

.form-field-input::placeholder {
  line-height: 1.5;
  font-weight: lighter;
}

.form-field-label {
  font-weight: bold;
  font-size: 0.625rem;
  margin-left: 0.1875rem;
  margin-bottom: -0.8rem;
}
.required-red {
  color: $crimson;
}

.form-field-error {
  color: $crimson;
  font-size: 0.875rem;
}

.error-red-underline {
  .MuiInput-underline:before,
  .MuiInput-underline:after {
    border-color: $crimson !important;
  }
}

input {
  font-family: PPObjectSans, sans-serif !important;
  font-size: 1rem !important;
  color: $mineShaft;
}
input:disabled {
  opacity: 0.7;
  -webkit-opacity: 70%;
}

body > .gigya-password-strength-bubble#gigya-screenset-slot_bubble {
  display: none;
}

#long-menu {
  li {
    font-family: PPObjectSans, sans-serif;
    font-size: 14px;
  }
}

header {
  img[alt="logo"], img[alt="Frontier"] {
    width: 48px !important;
    height: 48px !important;
  }
}